<template>
  <div class="elevator-item">
    <div class="row">
      <div class="item-title-wrap">
        <div class="item-title-txt">升降机监控</div>
      </div>
    </div>
    <div class="row">
      <!-- 设备列表 -->
      <div class="r1-c1">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">设备列表</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-1 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div class="r1-c1-content-wrap">
                <div
                  v-for="(item, index) in deviceList"
                  class="r1-c1-content-item"
                  :class="[
                    index == currentDeviceIndex
                      ? 'r1-c1-content-item-active'
                      : '',
                    item.online == 1 ? '' : 'r1-c1-content-item-offline',
                  ]"
                  :key="index"
                  @click="onToggleDevice(index)"
                >
                  <div>{{ item.deviceName }}</div>
                  <div>({{ item.serialNumber }})</div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <!-- 实时数据 -->
      <div class="r1-c2">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">实时数据</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-1 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div class="r1-c2-content-wrap">
                <div class="content-top">
                  {{ currentDevice ? currentDevice.deviceName : "" }}
                  （{{ currentDevice ? currentDevice.serialNumber : "" }}）
                  {{
                    currentDeviceRealData.length
                      ? currentDeviceRealData[0].uploadDate
                      : "0000-00-00 00:00:00"
                  }}
                </div>
                <div class="content-body">
                  <div class="content-body-item">
                    <img
                      class="icon"
                      src="../assets/device/elevator/height.png"
                    />
                    <div class="value">
                      <div>实时起重量</div>
                      <div>
                        {{
                          currentDeviceRealData.length &&
                          currentDeviceRealData[0].weight
                            ? currentDeviceRealData[0].weight
                            : "0"
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="content-body-item">
                    <img
                      class="icon"
                      src="../assets/device/elevator/percent.png"
                    />
                    <div class="value">
                      <div>重量百分比</div>
                      <div>
                        {{
                          currentDeviceRealData.length &&
                          currentDeviceRealData[0].weightPercent
                            ? currentDeviceRealData[0].weightPercent
                            : "0"
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="content-body-item">
                    <img
                      class="icon"
                      src="../assets/device/elevator/height.png"
                    />
                    <div class="value">
                      <div>实时高度</div>
                      <div>
                        {{
                          currentDeviceRealData.length &&
                          currentDeviceRealData[0].height
                            ? currentDeviceRealData[0].height
                            : "0"
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="content-body-item">
                    <img
                      class="icon"
                      src="../assets/device/elevator/speed.png"
                    />
                    <div class="value">
                      <div>实时速度</div>
                      <div>
                        {{
                          currentDeviceRealData.length &&
                          currentDeviceRealData[0].speed
                            ? currentDeviceRealData[0].speed
                            : "0"
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="content-body-item">
                    <img
                      class="icon"
                      src="../assets/device/elevator/tilt.png"
                    />
                    <div class="value">
                      <div>实时倾斜度</div>
                      <div>
                        {{
                          currentDeviceRealData.length &&
                          currentDeviceRealData[0].tilt1
                            ? currentDeviceRealData[0].tilt1
                            : "0"
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- 数据统计 -->
      <div class="r2-c1">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">数据统计</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div class="r2-c1-content-wrap">
                <div class="menu">
                  <div
                    class="menu-item"
                    @click="toggleCurrentCurveName(item)"
                    :class="[
                      item == currentCurveName ? 'menu-item-active' : '',
                    ]"
                    v-for="(item, index) in currentDeviceCurveTypeList"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
                <div id="echarts-elevator-item-r2-c1"></div>
              </div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <!-- 司机信息 -->
      <div class="r2-c2">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">司机信息</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div class="r2-c2-content-wrap">
                <div class="driver-info">
                  <div class="name">项目名称</div>
                  <div class="value">{{currentDeviceDriverData.projectName}}</div>
                </div>
                <div class="driver-info">
                  <div class="name">设备名称</div>
                  <div class="value">{{currentDeviceDriverData.deviceName}}</div>
                </div>
                <div class="driver-info">
                  <div class="name">司机姓名</div>
                  <div class="value">{{currentDeviceDriverData.deviceDriver}}</div>
                </div>
                <div class="driver-info">
                  <div class="name">认证时长</div>
                  <div class="value">{{currentDeviceDriverData.authTime}}</div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <!-- 塔吊视频 -->
      <div class="r2-c3">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">升降机视频</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <!--  -->
            <div class="border-body-middle grow">
              <div class="playerWrap">
                <div id="player"></div>
              </div>
            </div>
            <!--  -->
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "elevatorItem",
  components: {},
  data() {
    return {
      deviceList: [],
      currentDeviceIndex: null,
      currentCurveName: "",
      currentDeviceRealData: [],
      currentDeviceCurveData: null,
      currentDeviceDriverData: {
        authTime: "",
        deviceDriver: "",
        projectId: 0,
        projectName: "",
        id: 0,
        deviceName: "",
        serialNumber: "",
      },
      echarts: {
        echartsCurveR2C1: null,
      },
      videoData: null,
      player: null,
    };
  },
  computed: {
    projectList: function () {
      return this.$store.state.projectList;
    },
    currentProject: function () {
      return this.$store.state.currentProject;
    },
    currentDevice: function () {
      return this.deviceList[this.currentDeviceIndex];
    },
    currentDeviceCurveTypeList: function () {
      var list = [];
      if (this.currentDeviceCurveData) {
        this.currentDeviceCurveData.series.forEach((item, index) => {
          list.push(item.name);
        });
      }
      return list;
    },
    currentCurveData: function () {
      if (!this.currentCurveName || !this.currentDeviceCurveData) {
        return null;
      } else {
        var data = {
          time: this.currentDeviceCurveData.time,
          series: null,
        };
        this.currentDeviceCurveData.series.forEach((item, index) => {
          if (item.name == this.currentCurveName) {
            data.series = item.data;
          }
        });
        return data;
      }
    },
  },
  watch: {
    // 监听项目切换
    currentProject(newVal, oldVal) {
      console.log("watch currentProject =>", newVal);
      this.$router.push({ name: "Elevator" }); // 返回上级页面
      // this.onGetDeviceListData(); // 获取设备列表
    },
    // 监听设备切换
    currentDeviceIndex(newVal, oldVal) {
      console.log("watch currentDeviceIndex =>", newVal);
      this.onGetCurrentDeviceRealData(); // 获取当前设备实时数据
      this.onGetCurrentDeviceCurveData(); // 获取当前设备曲线数据
      this.onGetCurrentDeviceDriverData(); // 获取当前设备司机信息
      this.getVideoData(); // 获取视频参数
    },
    // 监听曲线图数据更新，重置菜单选中第一个曲线序列
    currentDeviceCurveData(newVal, oldVal) {
      console.log("watch currentDeviceCurveData =>", newVal);
      if (newVal) {
        this.currentCurveName = newVal.series[0].name;
      }
    },
    // 监听菜单点选新的曲线序列
    currentCurveData: {
      handler: function (newVal, oldVal) {
        console.log("watch currentCurveData =>", newVal);
        if (!newVal) return;
        this.echartsCurveR2C1Init(); // 绘制曲线
      },
      deep: true,
    },
  },
  mounted() {
    this.onGetDeviceListData(); // 获取设备列表
  },
  methods: {
    // 获取设备列表
    onGetDeviceListData: function () {
      this.currentDeviceIndex = null;
      if (!this.currentProject.id) {
        return;
      }
      var that = this;
      var data = {
        seType: 3,
        projectId: this.currentProject.id,
      };
      this.$axiosAdmin({
        method: "post",
        url: "api/screen/specialEquipment/particularByPidAndSeType",
        data: JSON.stringify(data),
      })
        .then((res) => {
          if (res.code === 0) {
            that.deviceList = res.data;
            that.currentDeviceIndex = 0;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取当前设备实时数据
    onGetCurrentDeviceRealData: function () {
      if (this.currentDeviceIndex == null) {
        return;
      }
      var that = this;
      var data = {
        serialNumber: this.currentDevice.serialNumber,
        seType: 3
      };
      this.$axiosAdmin({
        method: "post",
        url: "api/screen/specialEquipment/particularRealDataBySerNumber",
        data: JSON.stringify(data),
      })
        .then((res) => {
          if (res.code === 0) {
            that.currentDeviceRealData = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取当前设备曲线数据
    onGetCurrentDeviceCurveData: function () {
      this.currentDeviceCurveData = null;
      if (this.currentDeviceIndex == null) {
        return;
      }
      var that = this;
      var data = {
        seType: 3,
        curveType: 1,
        projectId: this.currentProject.id,
        serialNumber: this.currentDevice.serialNumber,
      };
      this.$axiosAdmin({
        method: "post",
        url: "api/screen/specialEquipment/particularCurveByPidSidAndSeType",
        data: JSON.stringify(data),
      })
        .then((res) => {
          if (res.code === 0) {
            that.currentDeviceCurveData = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取当前设备司机信息
    onGetCurrentDeviceDriverData: function () {
      var that = this;
      var data = {
        serialNumber: this.currentDevice.serialNumber,
        projectId: this.currentProject.id,
      };
      this.$axiosAdmin({
        method: "post",
        url: "api/screen/specialEquipment/particularDriverById",
        data: JSON.stringify(data),
      })
        .then((res) => {
          if (res.code === 0) {
            that.currentDeviceDriverData = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换曲线数据
    toggleCurrentCurveName: function (item) {
      this.currentCurveName = item;
    },
    // 切换设备
    onToggleDevice: function (index) {
      this.currentDeviceIndex = index;
    },
    // 绘制曲线图
    echartsCurveR2C1Init: function () {
      this.echarts.echartsCurveR2C1 = this.$echarts.init(
        document.getElementById("echarts-elevator-item-r2-c1")
      ).dispose();
      this.echarts.echartsCurveR2C1 = this.$echarts.init(
        document.getElementById("echarts-elevator-item-r2-c1")
      );
      var option = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
          axisPointer: {
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(0, 255, 233,0)",
                  },
                  {
                    offset: 0.5,
                    color: "rgba(255, 255, 255,1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(0, 255, 233,0)",
                  },
                ],
                global: false,
              },
            },
          },
        },
        grid: {
          top: "5%",
          left: "5%",
          right: "5%",
          bottom: "25%",
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
            },
            splitArea: {
              // show: true,
              color: "#f00",
              lineStyle: {
                color: "#f00",
              },
            },
            axisLabel: {
              color: "#00e4ff",
            },
            splitLine: {
              show: false,
            },
            boundaryGap: false,
            data: this.currentCurveData.time,
          },
        ],

        yAxis: [
          {
            type: "value",
            min: 0,
            // max: 140,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,0.1)",
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
              margin: 20,
              textStyle: {
                color: "#d1e6eb",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: this.currentCurveName,
            type: "line",
            smooth: true, //是否平滑
            showAllSymbol: true,
            symbol: "circle",
            symbolSize: 15,
            lineStyle: {
              normal: {
                color: "#00b3f4",
                shadowColor: "rgba(0, 0, 0, .3)",
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#00b3f4",
              },
            },
            itemStyle: {
              opacity: 0,
              // color: "#00b3f4",
              // borderColor: "#fff",
              // borderWidth: 3,
              // shadowColor: "rgba(0, 0, 0, .3)",
              // shadowBlur: 0,
              // shadowOffsetY: 2,
              // shadowOffsetX: 2,
            },
            tooltip: {
              show: true,
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(0,179,244,0.3)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0,179,244,0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(0,179,244, 0.9)",
                shadowBlur: 20,
              },
            },
            data: this.currentCurveData.series,
          },
        ],
      };
      this.echarts.echartsCurveR2C1.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.echartsCurveR2C1.resize();
      });
    },
    // 获取升降机视频
    getVideoData: function () {
      var that = this;
      var data = {
        projectId: this.currentProject.id,
        serialNumber: this.currentDevice.serialNumber
      };
      this.$axiosAdmin({
        method: "post",
        url: "api/screen/specialEquipment/equipmentRelevancyVideo",
        data: JSON.stringify(data),
      })
        .then((res) => {
          if (res.code === 0) {
            that.videoData = res.data;
          } else {
            that.videoData = null;
          }
          this.onPlayVideo(); // 播放视频
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 播放升降机视频
    onPlayVideo: function () {
      var that = this
      var Dom = document.getElementById('player');
      Dom.innerHTML = '';
      this.player = null
      if (this.videoData) {
        this.player = new EZUIKit.EZUIKitPlayer({
          id: "player",
          url: that.videoData.ezopenUrl,
          accessToken: that.videoData.token,
          template: "simple", // simple-极简版; standard-标准版; security-安防版(预览回放); voice-语音版
        });
      }
    }
  },
};
</script>

<style lang="less" scoped>
@Width: 192rem;
.row-height-1 {
  height: 245 / @Width;
}
.row-height-2 {
  height: 345 / @Width;
}
.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.elevator-item {
  width: 100%;
  height: 100%;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20 / @Width;
  }
  .item-title-wrap {
    width: 1840 / @Width;
    height: 60 / @Width;
    background-image: url("../assets/item-title-bg.png");
    filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;
  }
  .item-title-txt {
    text-align: center;
    font-size: 24 / @Width;
    color: #00e4ff;
    line-height: 60 / @Width;
  }
  .r1-c1 {
    width: 590 / @Width;
    height: 320 / @Width;
  }
  .r1-c2 {
    width: 1220 / @Width;
    height: 320 / @Width;
  }
  .r2-c1 {
    width: 880 / @Width;
    height: 420 / @Width;
  }
  .r2-c2 {
    width: 400 / @Width;
    height: 420 / @Width;
  }
  .r2-c3 {
    width: 500 / @Width;
    height: 420 / @Width;

    .playerWrap {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
    }
    #player {
      width: 450 / @Width;
      height: 253 / @Width;
      background-color: black;
    }
  }
  .border-wrap {
    .flex {
      display: flex;
    }
    .grow {
      flex-grow: 1;
    }
    .border-top {
      .border-top-left,
      .border-top-right {
        width: 50 / @Width;
        height: 50 / @Width;
        img {
          width: 50 / @Width;
          height: 50 / @Width;
        }
      }
      .border-top {
        height: 50 / @Width;
        img {
          width: 100%;
          height: 50 / @Width;
        }
      }
      .border-title {
        width: 205 / @Width;
        height: 50 / @Width;
        background-image: url("../assets/border/border-title.png");
        filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
        -moz-background-size: 100% 100%;
        background-size: 100% 100%;
        .border-name {
          color: #00e4ff;
          font-size: 18 / @Width;
          font-weight: 700;
          text-align: center;
          margin-top: 8 / @Width;
        }
      }
    }

    .border-body {
      .border-left,
      .border-right {
        width: 25 / @Width;
        img {
          width: 25 / @Width;
          height: 100%;
        }
      }
      .border-body-middle {
        background-image: url("../assets/border/border-body-bg.png");
        filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
        -moz-background-size: 100% 100%;
        background-size: 100% 100%;
      }
    }

    .border-bottom {
      .border-bottom-left,
      .border-bottom-right {
        width: 25 / @Width;
        height: 25 / @Width;
        img {
          width: 25 / @Width;
          height: 25 / @Width;
        }
      }
      .border-bottom {
        height: 25 / @Width;
        img {
          width: 100%;
          height: 25 / @Width;
        }
      }
      .border-bottom-middle {
        width: 258 / @Width;
        height: 25 / @Width;
        img {
          width: 258 / @Width;
          height: 25 / @Width;
        }
      }
    }
  }
}
.r1-c1-content-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: scroll;
  .r1-c1-content-item {
    text-align: center;
    color: #00e4ff;
    width: 170 / @Width;
    height: 80 / @Width;
    margin-top: 5 / @Width;
    margin-left: 5 / @Width;
    margin-right: 7 / @Width;
    margin-bottom: 7 / @Width;
    background-image: url("../assets/device/list/item-bg.png");
    filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .r1-c1-content-item:nth-child(3n) {
    margin-right: 0;
  }
  .r1-c1-content-item-active {
    box-shadow: 0 0 5 / @Width #fff;
  }
  .r1-c1-content-item-offline {
    background-image: url("../assets/device/list/item-offline-bg.png");
    color: #fff;
  }
}
.r1-c2-content-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  .content-top {
    font-size: 18 / @Width;
    position: absolute;
    top: -30 / @Width;
    color: #00e4ff;
  }
  .content-body {
    width: 100%;
    height: 100%;
    // background-color: pink;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .content-body-item {
      width: 130 / @Width;
      // background-color: #fff;
      .icon {
        width: 130 / @Width;
        height: 130 / @Width;
      }
      .value {
        width: 130 / @Width;
        height: 56 / @Width;
        background-image: url("../assets/device/realData/value-bg.png");
        filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
        -moz-background-size: 100% 100%;
        background-size: 100% 100%;
        margin-top: 10 / @Width;
        text-align: center;
        font-size: 16 / @Width;
        color: #00e4ff;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
.r2-c1-content-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .menu {
    height: 46 / @Width;
    display: flex;
    font-size: 18 / @Width;
    line-height: 18 / @Width;
    .menu-item {
      height: 18 / @Width;
      color: #00e4ff;
      margin: 10 / @Width 0;
      padding: 0 30 / @Width;
      border-right: 1 / @Width solid #00e4ff;
    }
    .menu-item:last-child {
      border-right: 0;
    }
    .menu-item-active {
      color: #fff;
    }
  }
  #echarts-elevator-item-r2-c1 {
    width: 100%;
    flex-grow: 1;
  }
}
.r2-c2-content-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #00e4ff;
  font-size: 16 / @Width;
  .driver-info {
    width: 100%;
    height: 65 / @Width;
    line-height: 65 / @Width;
    text-align: center;
    display: flex;
    border-top: 1px solid rgba(0, 121, 253, 0.5);
    .name {
      width: 100 / @Width;
      background: linear-gradient(
        to left,
        rgba(26, 62, 184, 0.8),
        rgba(26, 62, 184, 0.1)
      );
    }
    .value {
      flex-grow: 1;
    }
  }
  .driver-info:last-child {
    border-bottom: 1px solid rgba(0, 121, 253, 0.5);
  }
}
</style>
